var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-title":""}},[_c('b-row',[_c('b-col',{staticClass:"mx-auto",attrs:{"xs":"12"}},[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t('card.title.modify.user'))+" "),_c('div',{staticClass:"float-right mr-1"},[_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":_vm.deleteEntity}},[(!this.$helpers.isMobileResolution() && !this.$helpers.isSmallResolution())?_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t('label.button.delete')))]):_c('feather-icon',{attrs:{"icon":"XCircleIcon"}})],1)],1)])],1)],1),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t('card.description.modify.user'))+" ")])],1),_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.name')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.name').toLowerCase(),"rules":"required","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.name').toLowerCase()},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.email')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.email').toLowerCase(),"rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.email').toLowerCase()},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.password')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.password').toLowerCase(),"vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"***********"},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.bio')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.bio').toLowerCase(),"rules":"required","vid":"bio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.bio').toLowerCase()},model:{value:(_vm.formData.bio),callback:function ($$v) {_vm.$set(_vm.formData, "bio", $$v)},expression:"formData.bio"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('label.button.save'))+" ")]),_c('cancel-and-g-o-t-o-previous-page',{staticClass:"mr-2"})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }